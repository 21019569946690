<template>
  <div>
    <!-- Banner Section -->
    <section
      class="banner d-flex align-items-center justify-content-center cleartx"
    >
      <div class="container-fluid">
        <img src="../assets/img/CaseStudies/Blog.jpg" class="img" alt="csr" />
        <div class="container csrbanner">
          <h2 class="csrbannertitle">Blogs</h2>
        </div>
      </div>
    </section>

    <section class="cleartx mt-5">
      <div class="container">
        <div class="row">
          <!-- Card 2 -->
          <div class="row">
            <!-- Card 1 -->

          
            <div
              class="col-md-4"
              v-for="(item, index) in paginatedNewsroom"
              :key="index"
            >
              <div class="card border-0" @click="blogsDetails(item.Bid)">
                <img
                  :src="item.Blogs_Image_url"
                  class="card-img-top"
                  :alt="item.Btitle"
                  style="border: 1px solid black; border-radius: 39px"
                />
                <div class="card-body border-0">
                  <button
                    type="button"
                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4"
                  >
                    News
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-dark rounded-pill custom-badge-load"
                  >
                    {{ item.Bdate }}
                  </button>
                  <h4 class="mt-3">{{ item.Btitle }}</h4>
                  <p>
                    {{ truncatedText(item.blogs_smalldescription) }}...
                    <a :href="item.Blogs_ReadMore" target="_blank" class="bgcolor">Read More</a>
                  </p>
                </div>
              </div>
            </div>

            

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainBlogsComponent",
  data() {
    return {
      loaders: false,
      currentPage: 1, // Tracks the current page
      pageSize: 9, // Number of items per page
    };
  },
  computed: {
    ...mapGetters(["fetchBlogs"]), // Fetch data from Vuex getter

    //Calculate total pages
    totalPages() {
      return Math.ceil(this.fetchBlogs.length / this.pageSize);
    },
    // Get paginated items
    paginatedNewsroom() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.fetchBlogs.slice(start, end);
    },
  },
  methods: {
    blogsDetails(id)
    {
        this.$router.push({ name: "MainblogsDetails", params: { id: id } });
    },
    // Truncate text to display a limited preview
    truncatedText(tx) {
      if (!tx) return ""; // Handle cases where text is null or undefined
      return tx.split(" ").slice(0, 20).join(" ");
    },
    // Dispatch action to fetch newsroom data
    newsroom() {
      this.$store.dispatch("fetchBlogs");
    },
    // Navigate to the next page
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // Navigate to the previous page
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  created() {
    this.loaders = true; // Show loader before fetching
    this.newsroom(); // Fetch newsroom data on component creation
  },
};
</script>

<style scoped>
.responsive {
  max-width: 100%;
  height: auto;
}

.pagination button {
  cursor: pointer;
}
.pagination span {
  line-height: 38px;
  margin: 0 10px;
}
</style>