<template>
  <div>
    
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/IndustrialPark/homesection.webp" class="img" alt="Industrial Parks">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Industrial Parks</h2>
            </div>
        </div>
    </section>

    <section class="cleartx connectBgvector mt-5 mt-md-0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/  INDUSTRIAL PARKS<span class="green-dot"></span> </h3>
                    <br>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    
                    <h2 class="industrialTitle" data-aos="fade-up"><img src="../assets/img/IndustrialPark/pip.png" width="150" height="100" data-aos="fade-right" alt="" class="img-fluid mb-3"> by Arete, A Premier industrial park developer based in Gujarat</h2>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-5" data-aos="fade-up">
                    <p class="industrialDescrption">At PIP by Arete, we bring over three decades of unparalleled experience in land acquisition and supporting industrial growth. We have transformed into a structured industrial asset developer based out of Gujarat. We create efficient industrial destinations equipped with world class infrastructure to enable manufacturing industries to establish their greenfield projects.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 ">
                    <h2 class="industrialTitle" data-aos="fade-up">Our Portfolio </h2>
                    <p class="industrialDescrption" data-aos="fade-up">We proudly own an extensive portfolio of 9,000 acres of Government approved land which is categorized into 9 industrial park clusters in the
                        strategically significant Dahej-Vadodara region of Gujarat and 3,500 acres under various stages of development.</p>
                    <p class="industrialDescrption" data-aos="fade-up">Our comprehensive services extend beyond land acquisition; we provide essential liaison support to
                        navigate the regulatory landscape, ensuring a seamless establishment process for your business.
                    </p>
                    <div class="col-sm-12 mt-5">
                        <div class="card-group">
                            <div class="card border-0" data-aos="fade-up">
                                <div class="card-body">
                                    <h5 class="card-title home-second-section-title">9,000</h5>
                                    <p class="card-text carddescription">acres of land approved by Government of Gujarat</p>
                                </div>
                            </div>
                            <div class="slant-line1" ></div>
                            <!-- <div class="card border-0" data-aos="fade-up">
                                <div class="card-body">
                                    <h5 class="card-title home-second-section-title">3,500</h5>
                                    <p class="card-text carddescription">acres already operational</p>
                                </div>
                            </div>
                            <div class="slant-line1" ></div> -->
                            <div class="card border-0" data-aos="fade-up">
                                <div class="card-body">
                                    <h5 class="card-title home-second-section-title">3,500</h5>
                                    <p class="card-text carddescription">acres under various stages of development.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-5" data-aos="fade-up">
                    <img src="../assets/img/IndustrialPark/Ip_1.webp" class="img-fluid fill-available" alt="Media topic">
                </div>
            </div>
            
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row d-flex flex-column-reverse-mobile">
                <div class="col-sm-6" data-aos="fade-up">
                    <img src="../assets/img/IndustrialPark/IP_2.webp" class="card-img-top" alt="Media topic">
                </div>
                <div class="col-sm-6 g-5  align-self-center">
                    <h2 class="industrialTitle" data-aos="fade-up">
                        Environmental Commitment
                    </h2>
                    <p class="industrialDescrption mt-4" data-aos="fade-up">Our industrial parks have successfully obtained Environmental Clearance for a plant with a capacity of 50 MLD. This achievement guarantees that the treated effluent from our Common Effluent Treatment Plant (CETP) meets the stringent GPCB/Marine discharge norms, allowing for safe discharge.</p>
                </div>

            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container plugandplaybgcolor">
        <div class="row p-4">
            <div class="col-sm-6 col align-self-center mt-5 mb-5 p-0 p-md-5" >
                <h2 class="plugandplaytitle-second">Plug and Play Solution</h2><br>
                <p class="plugandplaydescription">With everything from effluent treatment to energy requirements, and raw material supply to all the necessary utilities being made available right at the doorstep, our industrial parks offer a true plug-and-play solution that help industries set up and get started faster.</p>
            </div>
            <div class="col-sm-6 d-flex justify-content-around mt-5 mb-5">
                <img src="../assets/img/IndustrialPark/Plug.webp" alt="" srcset="" class="img-fluid">
            </div>
        </div>
        </div>
    </section>

    <!-- slider  -->
    <section class="cleartx">
        <div class="container d-flex align-items-stretch justify-content-center w-100">
            <div class="row ">
                <div class="value-section col-sm-12">
                    <div class="left-content col-sm-7 ">
                        <div class="title-section">
                            <h2 class="industrialTitle-Initiatives" data-aos="fade-up">Initiatives &nbsp;</h2>
                            <h2 class="subtitle" data-aos="fade-left"> beyond infrastructure</h2>
                        </div>
                    </div>
                    <div class="right-content col-sm-5">
                        <div class="slant-line d-md-block" ></div>
                        <p class="description" data-aos="fade-up">At Arete, we go beyond infrastructure and regulatory support to partner industries in meeting their sustainable development goals through a number of carefully planned new energy initiatives.</p>
                    </div>
                </div>
               
            </div>
            
        </div>
        <div class="container">
            <div class="row  g-3 p-0">
                <div class="col-md-3">
                    <div class="card IndustrialPark-custom-card h-100 p-5">
                        <img class="industrial-card-img-top text-left" width="80" height="80" src="../assets/img/IndustrialPark/IP_Card_1.png" data-aos="fade-down">
                        <div class="card-body p-0 mt-5 industrial-card-text-top">
                            <p class="card-text vluesTitle text-left" data-aos="fade-up">Green energy</p>
                            <p class="card-text text-left" data-aos="fade-up">We plan to develop a captive renewable energy plant to support the industries in their energy requirements.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card IndustrialPark-custom-card-1 h-100 p-5">
                        <img class="industrial-card-img-top text-left" width="80" height="80" src="../assets/img/IndustrialPark/IP_Card_2.png" data-aos="fade-down">
                        <div class="card-body p-0 mt-5 industrial-card-text-top">
                            <p class="card-text vluesTitle text-left" data-aos="fade-up">Decarbonisation</p>
                            <p class="card-text text-left" data-aos="fade-up">We have partnered with Fourier Earth, a Palo Alto-based startup, to generate green hydrogen for cleaner manufacturing.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card IndustrialPark-custom-card-2 h-100 p-5">
                        <img class="industrial-card-img-top text-left" width="80" height="80" src="../assets/img/IndustrialPark/IP_Card_3.png" data-aos="fade-down">
                        <div class="card-body p-0 mt-5 industrial-card-text-top">
                            <p class="card-text vluesTitle text-left" data-aos="fade-up">Raw Materials</p>
                            <p class="card-text text-left" data-aos="fade-up">A mix of on-site as well as direct supply corridors are in the works for assured supply of raw materials.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card IndustrialPark-custom-card-3 h-100 p-5">
                        <img class="industrial-card-img-top text-left" width="80" height="80" src="../assets/img/IndustrialPark/IP_Card_4.png" data-aos="fade-down">
                        <div class="card-body p-0 mt-5 industrial-card-text-top">
                            <p class="card-text vluesTitle text-left" data-aos="fade-up">ESG</p>
                            <p class="card-text text-left" data-aos="fade-up">Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="card IndustrialPark-custom-card-4 h-100 p-5">
                        <img class="industrial-card-img-top text-left" width="80" height="80" src="../assets/img/IndustrialPark/IP_Card_5.png" data-aos="fade-down">
                        <div class="card-body p-0 mt-5 industrial-card-text-top">
                            <p class="card-text vluesTitle text-left" data-aos="fade-up">Utilities</p>
                            <p class="card-text text-left" data-aos="fade-up">All the necessary utilities will be offered at the doorstep.</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2 class="industrialTitle">The Choice of Giants</h2>
                    <p class="industrialDescrption mt-3">
                        The park is already home to giants of the industry across varied categories, including Metallurgical, Specialty Chemicals, Agrochemicals, Rubber Chemicals, Lithium Battery Materials, Insulation Products, Surfactants, and Solvents.
                    </p>
                    <p class="industrialDescrption mt-2">
                        Our unwavering dedication to excellence positions us as the premier partner for enterprises looking to thrive in the industrial landscape of Gujarat.
                    </p>
                    <div class="mt-5">
                        <swiper :slidesPerView="2" 
                                                    :spaceBetween="40"
                                                    :centeredSlides="true"
                                                    :autoplay="{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }"
                                                    :pagination="false"
                                                    :navigation="false"
                                                    :modules="modules"
                                                    class="mySwiper"
                                                    loop="true"
                                                    :breakpoints="{
                                                    640: {
                                                        slidesPerView: 2,
                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                    },
                                                    1024: {
                                                        slidesPerView: 6,
                                                    }
                                                    }"
                                                    >
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/5.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/10.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/28.webp" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/29.webp" alt="The Economic Times" /></div></div></swiper-slide>
                                                    
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/30.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/31.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/32.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/33.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/34.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/35.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/36.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="cleartx">
        <data class="container">
            <data class="row" style="height: 600px;">
                <iframe id="iframe1" src="https://pip.areteservices.in/index.php/arete-group-pip" style="width: 100%;height:100%;"></iframe>

            </data>
        </data>
    </section> -->
   
   
     <!-- <section class="cleartx">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="industrialTitle">Upcoming Parks</h2>
            </div>
            <div class="col-sm-12 mt-5 mb-0">
              <div class="row g-5">
                <div class="col-md-8">
                  <div class="map-container">
                    <img src="../assets/img/IndustrialPark/map.png" class="map-image" alt="Map">
                    <div class="map-point" style="top: 54%; left: 75%;" @click="showDetails('ample')"></div>
                    <div class="map-point" style="top: 55%; left: 75%;" @click="showDetails('namrmada')"></div>
                    <div class="map-point" style="top: 56%; left: 74%;" @click="showDetails('aavkar')"></div>
                    <div class="map-point" style="top: 56%; left: 71%;" @click="showDetails('adahrubhut')"></div>
                    <div class="map-point" style="top: 55%; left: 84%;" @click="showDetails('apratim')"></div>
                    <div class="map-point" style="top: 57%; left: 73%;" @click="showDetails('ambience')"></div>
                    <div class="map-point" style="top: 54%; left: 87%;" @click="showDetails('bharuch')"></div>                    
                  </div>
                </div>
     
                <div class="col-md-4 mt-5">
                    <h3 id="park-name" class="park-name-style">{{ selectedPark.name }}</h3>
                    <div id="details-content" class="details-box p-4">
                      <div class="d-flex justify-content-center">
                        <img id="park-image" src="../assets/img/IndustrialPark/Check.jpg" alt="Park Image" class="img-fluid">
                      </div>
                      <p id="park-area" class="area-style">
                        {{ selectedPark.area }}
                      </p>
                      <p id="park-description">{{ selectedPark.description }}</p>
                    </div>
                </div>                  
              </div>
            </div>
          </div>
        </div>
      </section>  -->
   
      <!-- last section -->

     <section class="cleartx connectBgvector3">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-4 mt-5 mt-md-0">
                    <div class="industrialHeading">
                        <h3 class="text-uppercase breadcrumbs maintitle text-whiteGet" data-aos="fade-up">/ Get in Touch <span class="green-dot"></span> </h3>
                        <br>
                        <h2 class="industrialTitle-connect" data-aos="fade-up">Connect With Us</h2>
                        <br>
                        <p class="industrialdescription text-white" data-aos="fade-up">Discover how Arete can support your business objectives and drive your success in the industrial sector. </p>
                        <p class="industrialdescription text-white" data-aos="fade-up">Reach out to us today to learn more about our services and how we can assist you in your journey.</p>
                        <br>
                        <router-link id="brand-link" data-aos="fade-left" class="view-website-btn text-decoration-none mt-3 border-white text-white" to="/Contactus">Contact Us</router-link>
                    </div>
                  </div>
                  <div class="col-sm-6  d-flex justify-content-end p-2">
                    <img src="../assets/img/IndustrialPark/Vector.webp" class="img-fluid" alt="industialpark" data-aos="fade-right" srcset="">
                </div>
                
            </div>
        </div>
     </section>

    
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useHead } from '@vueuse/head';

useHead({
  title: 'Payal Industrial Park by Arete | Chemical Industrial Park',
  meta: [
    {
      name: 'description',
      content: 'Discover PIP by Aretes chemical park portfolio in Dahej - Vadodara, offering land, regulatory support, and seamless establishment services for chemical manufacturing business.',
    },
    {
      property: 'og:title',
      content: 'Payal Industrial Park by Arete | Chemical Industrial Park',
    },
    {
      property: 'og:description',
      content: 'Discover PIP by Aretes chemical park portfolio in Dahej - Vadodara, offering land, regulatory support, and seamless establishment services for chemical manufacturing business.',
    },
    {
      name: 'keywords',
      content: 'PIP by Arete group, Payal industrial park, Arete Chemical Industrial Park, chemical parks, chemical parks india, chemical park dahej, chemical park gujarat, chemical park with plug-and-play facilities, Payal chemical industrial park, chemical manufacturing park, chemical hub',
    },
  ],
});



export default {
    components: {
      Swiper,
      SwiperSlide
    },
  data() {
    return {
        modules: [Autoplay, Pagination, Navigation],
      // Store park details here
      parks: {
        ample: {
          name: 'Ample',
          area: '310 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        namrmada: {
          name: 'Namrmada',
          area: '298 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        aavkar: {
          name: 'Aavkar',
          area: '561 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        adahrubhut: {
          name: 'Adahrubhut',
          area: '495 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        apratim: {
          name: 'Apratim',
          area: '561 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        ambience: {
          name: 'Ambience',
          area: '200 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        bharuch: {
          name: 'Bharuch',
          area: '1452 Acres',
          image: 'img/IndustrialPark/Check.png',
          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        }
      },

      // Default selected park
      selectedPark: {
        name: '',
        area: '',
        image: '',
        description: ''
      },

      // Define positions of map points
      mapPoints: {
        ample: { top: '54%', left: '75%' },
        namrmada: { top: '61%', left: '72%' },
        aavkar: { top: '67%', left: '74%' },
        adahrubhut: { top: '73%', left: '71%' },
        apratim: { top: '73%', left: '84%' },
        ambience: { top: '80%', left: '73%' },
        bharuch: { top: '67%', left: '87%' }
      }
    };
  },
  methods: {
    showDetails(park) {
      // Update the selected park details
      this.selectedPark = this.parks[park];
    }
  },
  mounted() {
    // Automatically show details of a park when the component is mounted
    this.showDetails('ample'); // Call showDetails for the park you want as default
  }

};
</script>


<style scoped>
.slant-line1{
    width: 1px;
    height: 99px;
    transform: skewX(-20deg);
    margin-right: 1%;
    margin-top: 0;
    border: 0.5px solid #BFD730;
}
 .plugandplaydescription{
 
 font-size: 20px;
 font-weight: 400;
 line-height: 30px;
 text-align: justify;
 color: black;

}

.plugandplaytitle-second{
 font-size: 42px;
 font-weight: 600;
 line-height: 50.83px;
 text-align: left;
 color: rgba(15, 85, 123, 1);
}
.plugandplaybgcolor{
 background: var(--teal, rgba(120, 205, 209, 1));

}
 .map-container {
    position: relative;
  }
 
  .map-image {
    width: 100%;
    height: auto;
  }
 
  .map-point {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    cursor: pointer;
  }
 
 
  .park-name-style {
    line-height: 38.73px;
    text-align: left;
    margin-bottom: 15px; /* Adjust spacing between the name and the box */
  }
 
  .details-box {
    padding: 15px;
    border: 1px solid #ddd;
  }
 
  .area-style {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.73px;
    text-align: left;
    color: rgba(15, 85, 123, 1);
    margin-bottom: 5%; /* Spacing between Acres text and the image */
  }
 
  #park-image {
    margin-bottom: 15px;
  }
 
  #park-description {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }


  .IndustrialPark-custom-card:hover .industrial-card-img-top {
    content: url('../assets/img/IndustrialPark/IP_Card_Hover_1.png');
    transform: scale(1.3);
}

.IndustrialPark-custom-card-1:hover .industrial-card-img-top {
    content: url('../assets/img/IndustrialPark/IP_Card_Hover_2.png');
    transform: scale(1.3);
}

.IndustrialPark-custom-card-2:hover .industrial-card-img-top {
    content: url('../assets/img/IndustrialPark/IP_Card_Hover_3.png');
    transform: scale(1.3);
}

.IndustrialPark-custom-card-3:hover .industrial-card-img-top {
    content: url('../assets/img/IndustrialPark/IP_Card_Hover_4.png');
    transform: scale(1.3);
}

.IndustrialPark-custom-card-4:hover .industrial-card-img-top {
    content: url('../assets/img/IndustrialPark/IP_Card_Hover_5.png');
    transform: scale(1.1);
}

</style>