
<template>
    <div>
        <div id="loading-wrapper" v-if="loader">
            <div id="loading-text"></div>
            <div id="loading-content">
            <img src="../assets/img/loader.gif" alt="Loading" id="preloader-image">
            </div>
        </div>
    <section class="cleartax mt-5">
        <div class="video-container">
          <video id="introVideo" ref="introVideo"  muted loop playsinline @error="handleVideoError" @canplaythrough="handleVideoLoaded" v-if="videoSupported">
                  <source src="../assets/video/APL-Final.mp4" type="video/mp4" media="(min-width: 1920px)">    
                  <source src="../assets/video/APL-Final.mp4" type="video/mp4">
                  <source src="../assets/video/APL-Final.mp4" type="video/mp4">
                  <source src="../assets/video/APL-Final.mp4" type="video/mp4">
                  <source src="../assets/video/APL-Final.mp4" type="video/mp4">
                  <!-- Lower resolution video for mobile devices -->
                  Your browser does not support the video tag.
          </video>
  
          <video v-else id="introVideo" ref="introVideo"  muted loop playsinline>
              <source src="../assets/video/APL-Final.mp4" type="video/mp4">
              Your browser does not support the video tag.
          </video>

          <div class="ellipse-arrow11 position-absolute bottom-0 mb-3 me-3" @click="toggleMute">
          <div class="ellipse-arrow-gi">
              <img :src="srcmuted" class="img-fluid" alt="aretegroup" style="width: 40px;height: 40px;">
          </div>
      </div>

      </div>
      
      
    </section>    
    </div>
  </template>
  <script>
  
  import { useRouter } from 'vue-router'; // Import Vue Router


  export default {
     
      setup() {
        const router = useRouter(); // Get the router instance

        return {
          modules: [],
          router
        };
      },
    data() {
      return {
          activeTab: 'pillsHome',  // Default active tab
          videoSupported: true, // Tracks whether video is supported
          fallbackImage: require('../assets/img/Arete.png'), // Path to the fallback image
          isHidden: true,
          loader:true,
          autoplay:false,
          mutedtx: require('../assets/img/muted.png'), // Image for muted state
          unmutedtx: require('../assets/img/unmuted.png'), // Image for unmuted state
          srcmuted:require('../assets/img/unmuted.png'),
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      //this.checkInViewport();
     // this.createIntersectionObserver();
      // Check if video is supported
      const videoElement = this.$refs.introVideo;
   
      // If the browser doesn't support video formats, show the fallback image
      if (!videoElement.canPlayType) {
        this.videoSupported = false;
      }
  
      setTimeout(() => {
        this.loader = false;
        this.scrollerdown();
        this.checkPageLoaded(); // Ensure loader stays until full page load
        this.aplseason();
        //this.autoplay = true;
      }, 4000); // Adjust timeout duration based on your requirements



    },
    methods: {
        aplseason()
        {
            setTimeout(() => {
                this.router.push('/');
            },44000);
        },
      setActiveTab(tab) {
          setTimeout(() => {
              this.activeTab = tab;
          },500);
      },
      createIntersectionObserver() {
        const section = this.$el.querySelector(".videomutesec");
        const options = {
          root: null,
          threshold: 0.5, // Trigger when 50% of the section is in view
        };
   
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.muteVideo();
            }
          });
        }, options);
   
        observer.observe(section);
      },
      muteVideo() {
          const video = this.$refs.introVideo; // Use the correct ref name here
        if (video) {
          // Toggle muted state
          video.muted = true;
  
          // Optionally play the video if it's unmuted
          if (!video.muted && this.srcmuted) {
  
            video.play();
            this.srcmuted=this.unmutedtx;
          }
          else
          {
             this.srcmuted=this.unmutedtx;
          }
          }
      }, ////////////only for mute sect close
      checkPageLoaded() {
          // Check if entire page (including images and other content) is loaded
          window.addEventListener('load', () => {
            this.loader = false;
          });
        },
      handleVideoError() {
        // Called when there is a video error
        this.videoSupported = false;
      },
      toggleMute() {
        const video = this.$refs.introVideo; // Use the correct ref name here
        if (video) {
          // Toggle muted state
          video.muted = !video.muted;
  
          // Optionally play the video if it's unmuted
          if (!video.muted && this.srcmuted) {
  
            video.play();
            this.srcmuted=this.mutedtx;
          }
          else
          {
              this.srcmuted=this.unmutedtx;
          }
  
  
          
          // Log the current muted state (optional)
          console.log(`Video is now ${video.muted ? 'muted' : 'unmuted'}.`);
        } else {
          console.error("Video element is not defined.");
        }
      },
      hideArrow() {
        setTimeout(() => {
          this.isHidden = false;
        }, 70);
      },
      showArrow() {
        this.isHidden = true;
      },
      scrollerdown()
      {
          const video = this.$refs.introVideo; // Use the correct ref name here
              // Toggle muted state
          video.play();
      },
    }
  };
  
  </script>
  
  <style scoped>

  
/* ---- */
#loading-wrapper {
  width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    position: fixed;
    background: #000000ab;
}


#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white
}

#preloader-image {
  width: 50%; /* Adjust this size as needed */
  height: auto; /* Adjust this size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


  .ellipse-arrow11 {
      width: 56px;
      height: 56px;
      border-radius: 50%; /* Makes it an ellipse */
      background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; /* For positioning the arrow */
  }
   
  
  .ellipse-arrow-gi {
      width: 45px; /* Adjust as needed */
      height: 38px; /* Adjust as needed */
      background-size: contain; /* Adjust background size */
      background-color: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
  }
  
  .bannerbg{
      background: #fdfefd;
  }
  /* Add your styles here */
  
    
  .nav-pills .nav-link.active{
      background-color: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
  }
  

  
  .banner {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 776px !important;
    flex-shrink: 0;
    overflow: hidden;
}

.video-container video {
    animation: unset;
    animation-delay: 1s;
}
  
.video-container video {
    width: 100%;
    position: relative;
    height: 100%;
}

.mt-5 {
    margin-top: 8rem !important;
}

.video-container video {
    width: 93%;
    position: relative;
    height: 561px;
}

.video-container {
  position: relative; /* Ensures .ellipse-arrow11 aligns with this container */
}

.ellipse-arrow11 {
  position: absolute;
  bottom: 50%; /* Vertically centered */
  right: 22%;
  transform: translate(50%, 0%);
  z-index: 10;
}


  
@media (max-width: 600px) {
      .card {
          height: 150px;
      }

      .ellipse-arrow11[data-v-706e1e96] {
        position: absolute;
        bottom: 50%;
        right: 5%;
        transform: translate(50%, 12%);
        z-index: 100000000;
    }

  }

  @media (min-width: 600px) and (max-width: 768px) {

    .ellipse-arrow11[data-v-706e1e96] {
        position: absolute;
        bottom: 50%;
        right: 5%;
        transform: translate(50%, 12%);
        z-index: 100000000;
    }

  }

  </style>
  
  