<template>
    <div>

        <!-- Banner Section -->
        <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/Investments/homesection.webp" class="img" alt="csr">
                <div class="container csrbanner">
                    <h2 class="investmentBannertitle" data-aos="fade-up">Investments</h2>
                </div>
            </div>
        </section>

        

        <section class="cleartx investmentbgimg">
            <div class="container">
                <div class="row">
                    <div class="value-section p-sm-0">
                        <div class="left-content col-sm-8">
                            <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/
                                INVESTMENTS<span class="green-dot"></span> </h3>
                            <br>
                            <div class="title-section">
                                <h2 class="main-title-investment" data-aos="fade-up">Arete Investments: Pioneering<br>
                                    Strategic Growth Through<br> Value-Driven Investments</h2>
                            </div>
                        </div>
                        <div class="right-content col-sm-4 mt-3 mt-md-0">
                            <div class="slant-line-home-investment "></div>
                            <p class="description ms-sm-4" data-aos="fade-up">
                                At Arete Group, our Investments Division meticulously identifies and invests in
                                businesses with substantial long-term potential.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-0 mt-md-1">
            <div class="container">
                <div class="row ">
                    <div class="col-sm-12 row mt-5 p-sm-0" data-aos="fade-up">
                        <div class="col-sm-6">
                            <h4 class="investmentTitle">
                                Our Investment <br> Philosophy</h4>
                        </div>
                        <div class="col-sm-6" data-aos="fade-up">
                            <p class="mt-2 investmentDescription">
                                Our approach to investing and investments is founded on five core pillars, which further
                                define our backend research methodologies and thought processes to ensure that every
                                aspect of our approach is in alignment with our core philosophy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="cleartx">
            <div class="container p-0">
                <div class="d-sm-flex align-items-start flex-md-wrap">
                    <div class="col-sm-4">
                        <div class="nav flex-column nav-pills  " id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec active"
                                id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                                role="tab" aria-controls="v-pills-home" aria-selected="true" data-aos="fade-up">Quality
                                of Business & Management</button>
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec"
                                id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile"
                                type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"
                                data-aos="fade-up">Long-Term Orientation</button>
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec"
                                id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages"
                                type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"
                                data-aos="fade-down">The Larger Picture</button>
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec"
                                id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings"
                                type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"
                                data-aos="fade-down">The Ownership Angle</button>
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec"
                                id="v-pills-Value-Driven-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-Value-Driven" type="button" role="tab"
                                aria-controls="v-pills-Value-Driven" aria-selected="false" data-aos="fade-down">Value
                                Driven</button>
                            <button class="nav-link text-start mb-3 justify-content-start inverhoziantSec"
                                id="v-pills-Decisiveness-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-Decisiveness" type="button" role="tab"
                                aria-controls="v-pills-Decisiveness" aria-selected="false"
                                data-aos="fade-down">Decisiveness</button>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                aria-labelledby="v-pills-home-tab">
                                <!-- ------------------Quality of Business & Management--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8">
                                        <img src="../assets/img/Investments/QualityofBusiness.webp" data-aos="fade-up"
                                            class="img-fluid w-100" alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">We invest in businesses with robust economics
                                            (high ROCE & ROE), sustainable competitive advantages, and reputable
                                            management. These companies operate in ecosystems with high entry barriers
                                            and generate sustainable cash flows over the long term. We adopt a bottom-up
                                            approach, evaluating each business idea within our circle of competence.</p>
                                    </div>
                                </div>

                                <!-- ------------------Ending-----------LongTermOrientation---------------------- -->
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                aria-labelledby="v-pills-profile-tab">
                                <!-- ------------------Long-Term Orientation--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8" data-aos="fade-up">
                                        <img src="../assets/img/Investments/LongTermOrientation.webp"
                                            class="img-fluid w-100" alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">Our strategy is "Buy & Hold." We conduct
                                            fundamental analyses of companies, scrutinizing their core values,
                                            strategies, and management plans to enable future growth. We invest in
                                            opportunities and gradually build significant positions by adding to our
                                            investments at different levels. We focus on companies with irreplaceable
                                            products or services, avoiding those exposed to technological disruption.
                                            Patience and hard work precede our decisions.
                                        </p>
                                    </div>
                                </div>
                                <!-- ------------------Long-Term Orientation--------------------------------- -->
                            </div>
                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                aria-labelledby="v-pills-messages-tab">
                                <!-- ------------------The Larger Picture--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8" data-aos="fade-up">
                                        <img src="../assets/img/Investments/largerpic.webp" class="img-fluid w-100"
                                            alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">With a long-term investment view, we maintain a
                                            holistic perspective on growth potentials, focusing on the larger picture
                                            rather than short-term intricacies. We seek smaller companies with
                                            substantial growth potential, belonging to industries with significant
                                            tailwinds. Our independent research and conviction-building processes are
                                            thorough and in-depth.</p>
                                    </div>
                                </div>
                                <!-- ------------------The Larger Picture--------------------------------- -->

                            </div>
                            <div class="tab-pane fade" id="v-pills-settings" role="tabpanel"
                                aria-labelledby="v-pills-settings-tab">

                                <!-- ------------------TheOwnershipAngle--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8" data-aos="fade-up">
                                        <img src="../assets/img/Investments/TheOwnershipAngle.webp"
                                            class="img-fluid w-100" alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">We estimate owner’s earnings by understanding
                                            the risks a business faces and pricing in these risks. We understand
                                            business challenges and growth potentials, enabling us to make informed
                                            decisions during short-term problems. Our research endeavours to comprehend
                                            the management’s vision and decision-making criteria. It also gives us the
                                            courage and conviction required to stay through a company’s ups and downs.
                                        </p>
                                    </div>
                                </div>

                                <!-- ------------------TheOwnershipAngle--------------------------------- -->

                            </div>

                            <div class="tab-pane fade" id="v-pills-Value-Driven" role="tabpanel"
                                aria-labelledby="v-pills-Value-Driven-tab">

                                <!-- ------------------value driven--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8" data-aos="fade-up">
                                        <img src="../assets/img/Investments/ValueDriven.webp" class="img-fluid w-100"
                                            alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">Believing in the power of compounding, the price
                                            we pay is crucial. We value qualitative factors and price in risks
                                            accordingly. We imagine various scenarios for businesses and industries,
                                            evaluating potential Black Swan events. Confidence and non-ideological
                                            thinking guide our value assessments.</p>
                                    </div>
                                </div>

                                <!-- ------------------value driven--------------------------------- -->

                            </div>

                            <div class="tab-pane fade" id="v-pills-Decisiveness" role="tabpanel"
                                aria-labelledby="v-pills-Decisiveness-tab">

                                <!-- ------------------Decisiveness--------------------------------- -->
                                <div class="row g-3">
                                    <div class="col-sm-8" data-aos="fade-up">
                                        <img src="../assets/img/Investments/Decisiveness.webp" class="img-fluid w-100"
                                            alt="" srcset="">
                                    </div>
                                    <div class="col-sm-4 QualityofBusinessBG p-4" data-aos="fade-up">
                                        <p class="investmentDescr mt-3">Recognizing that great opportunities are
                                            infrequent, we prepare to act decisively when they arise. We build positions
                                            in existing investments and focus on a few well-researched ideas. Our
                                            disciplined approach involves writing down thoughts, evaluating objectively,
                                            and waiting for the right time. Sound temperament and thorough preparation
                                            make investing a "simple process." </p>
                                    </div>
                                </div>
                                <!-- ------------------Decisiveness--------------------------------- -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section class="cleartx">
        <div class="container p-sm-0">
            <div class="value-section p-sm-0 mb-1 mb-md-0">
                <div class="col-sm-8" >
                    <h2 class="ourinvestmentTitle" data-aos="fade-up">Our Investment Process</h2>
                </div>
                <div class="right-content col-sm-4" >
                    <div class="slant-line-home d-none d-md-block"></div>
                    <p class="description ms-sm-4" data-aos="fade-up">
                        Our disciplined investment process involves a proprietary four-stage research methodology:
                    </p>
                </div>
            </div>
            <div class="row justify-content-around">
                <div class="col-sm-6">
                    <img src="../assets/img/Investments/ourinvstment.webp" data-aos="fade-up" alt="" class="img-fluid" srcset="">
                </div>
                <div class="col-sm-6  d-flex align-items-center">
                    <ul class="nav flex-column">
                        <li class="nav-item mb-5 d-flex align-items-center">
                            <span class="number" data-aos="fade-left">01</span>
                            <img src="../assets/img/Investments/clipboard-icon.png" data-aos="fade-left" width="80" height="80" alt="Icon 1" class="icon ml-3"> 
                            <span class="description ml-3" data-aos="fade-down">Drawing the business canvas of the company and listing important parameters.</span>
                        </li>
                        <li class="nav-item mb-5 d-flex align-items-center">
                            <span class="number" data-aos="fade-right">02</span>
                            <img src="../assets/img/Investments/economics-icon.png" data-aos="fade-right" width="80" height="80" alt="Icon 2" class="icon ml-3"> 
                            <span class="description ml-3" data-aos="fade-down">Writing the business economics of the company and understanding the industry and competition.</span>
                        </li>
                        <li class="nav-item mb-5 d-flex align-items-center">
                            <span class="number" data-aos="fade-left">03</span>
                            <img src="../assets/img/Investments/value-assess-icon.png" data-aos="fade-left" width="80" height="80" alt="Icon 3" class="icon ml-3">
                            <span class="description ml-3" data-aos="fade-down">Deriving the value and assessing risks.</span>
                        </li>
                        <li class="nav-item d-flex align-items-center">
                            <span class="number" data-aos="fade-right">04</span>
                            <img src="../assets/img/Investments/second-level-thinking-icon.png" data-aos="fade-right" width="80" height="80" alt="Icon 4" class="icon ml-3">
                            <span class="description ml-3" data-aos="fade-down">Engaging in second-level thinking.</span>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    </section>

    </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Investment | Arete Group',
  meta: [
    {
      name: 'description',
      content: 'Arete Group focuses on identifying high-potential businesses for long-term growth. Our strategy is guided by five core pillars, ensuring alignment with our philosophy.',
    },
    {
      property: 'og:title',
      content: 'Investment | Arete Group',
    },
    {
      property: 'og:description',
      content: 'Arete Group focuses on identifying high-potential businesses for long-term growth. Our strategy is guided by five core pillars, ensuring alignment with our philosophy.',
    },
    {
      name: 'keywords',
      content: 'Arete group investment, investors in Vapi, business investment Gujarat, business investors India',
    },
  ],
});
</script>

<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}
</style>