<template>
  <div>
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
      <div class="container-fluid">
        <img
          src="../assets/img/CaseStudies/NewsroomBanner.png"
          class="img"
          alt="csr"
        />
        <div class="container csrbanner">
          <h2 class="csrbannertitle" data-aos="fade-up">Newsroom</h2>
        </div>
      </div>
    </section>

    <!-- Newsroom Section -->
    <section class="cleartx mt-5 mt-md-0">
      <div class="container">
        <div class="row mb-4">
          <div class="col-md-6">
            <h2 class="mediaTitle">Arete in The News</h2>
          </div>
          <div class="col-md-6 d-flex justify-content-end align-items-center">
          </div>
        </div>
        <div class="row">
          <!-- Paginated Newsroom Items -->
          <div
            class="col-md-4"
            v-for="(item, index) in paginatedNewsroom"
            :key="index"
          >
            <div class="card border-0">
              <img
                :src="item.Image_url"
                class="card-img-top"
                :alt="item.arete_title"
                style="border: 1px solid black; border-radius: 39px;"
              />
              <div class="card-body border-0">
                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">{{ item.Ndate }}</button>
                <h3 class="mt-3">{{ item.Ntitle }}</h3>
                <p>
                  {{ truncatedText(item.arete_description) }}...
                  <a :href="item.arete_ReadMore" target="_blank" class="bgcolor">Read More</a>
                </p>
              </div>
            </div>
          </div>

          <!-- Pagination Controls -->
          <div class="pagination mt-4 d-flex justify-content-center">
            <button
              @click="prevPage"
              :disabled="currentPage === 1"
              class="btn btn-outline-dark me-2"
            >
              Previous
            </button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="btn btn-outline-dark ms-2"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllRoomComponent",
  data() {
    return {
      loaders: false,
      currentPage: 1, // Tracks the current page
      pageSize: 9, // Number of items per page
    };
  },
  computed: {
    ...mapGetters(["fetchNewsroom"]), // Fetch data from Vuex getter

    // Calculate total pages
    totalPages() {
      return Math.ceil(this.fetchNewsroom.length / this.pageSize);
    },

    // Get paginated items
    paginatedNewsroom() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.fetchNewsroom.slice(start, end);
    },
  },
  methods: {
    // Truncate text to display a limited preview
    truncatedText(tx) {
      if (!tx) return ""; // Handle cases where text is null or undefined
      return tx.split(" ").slice(0, 20).join(" ");
    },
    // Dispatch action to fetch newsroom data
    newsroom() {
      this.$store.dispatch("fetchNewsroom");
    },
    // Navigate to the next page
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // Navigate to the previous page
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  created() {
    this.loaders = true; // Show loader before fetching
    this.newsroom(); // Fetch newsroom data on component creation
  },
};
</script>

<style scoped>
.responsive {
  max-width: 100%;
  height: auto;
}

.pagination button {
  cursor: pointer;
}
.pagination span {
  line-height: 38px;
  margin: 0 10px;
}
</style>
