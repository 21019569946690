<template>
  <div>
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
      <div class="container-fluid">
        <img src="../assets/img/CaseStudies/Blog.jpg" class="img" alt="csr" />
        <div class="container csrbanner">
          <h2 class="csrbannertitle">Blogs</h2>
        </div>
      </div>
    </section>

    <!-- Blog Section -->
    <section class="cleartx mt-4">
      <div class="container">
        <div class="row g-5">
          <!-- Blog Cards -->
         
          <div 
            class="col-md-4" 
            v-for="(item, index) in blog" 
            :key="index"
          >
            <div class="card h-100">
              <div class="card-body">
                <h5 class="card-title">{{ item.Btitle }}</h5>
                <p class="card-text">{{ item.Bdescription }}</p>
              </div>
            </div>
          </div>

          <!-- No Blogs Found -->
          <div 
            class="col-sm-12 mt-5" 
            v-if="blog.length === 0"
          >
            <h2 class="text-center">No Blogs Found</h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blogId: this.$route.params.id, // Extract the blog ID from the route
    };
  },
  computed: {
    // Access blog details from Vuex store
    blog() {
      return this.$store.getters.blogDetails;
    },
  },
  methods: {
    // Dispatch action to fetch blog details
    fetchBlog_Details() {
      this.$store.dispatch('fetchBlogDetails', this.blogId)
        .catch(error => {
          console.error('Error fetching blog details:', error);
        });
    },
  },
  created() {
    this.fetchBlog_Details(); // Fetch blog data on component creation
  },
};
</script>

