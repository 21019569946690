<template>
    <div>
        <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/Media/mediaSection.png" class="img" alt="csr">
                <!-- <div class="container-fluid">
              <div class="row justify-content-center align-items-center">
                  <div class="col-md-8 text-center">
                      <img src="./img/About/homesectionbg.png" class="img-fluid position-relative" alt="csr">
                      <h2 class="aboutbannertitle">Media</h2>
                  </div>
              </div>
          </div> -->
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 mt-5">
                        <p style=" font-size: 16px; font-weight: 500; line-height: 19.36px; text-align: left; ">
                            / NEWS & MEDIA <span class="green-dot"></span></p>
                        <h2 class="mediaTitle">
                            Our Latest Activities
                        </h2>
                    </div>
                </div>
            </div>
            <!-- ----------------------- -->
            <div class="container mt-3">
                <div class="row">
                    <div class="col-sm-6 ">
                        <div class="card border-0 mt-5">
                            <div class="elfsight-app-d5e374c3-386b-47d7-a0c4-67b1346965e7" data-elfsight-app-lazy></div>
                            <div class="card-body border-0 mt-4">
                                <h5 class="card-title mt-3">Linkedin</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        
                        <div class="card border-0"  @click="Newsroom()">
                            <img src="../assets/img/Media/newsroom6.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th
                                    October, 2024</button>
                                <h5 class="card-title mt-3">Newsroom</h5>
                            </div>
                        </div>
                        <div class="card border-0 " @click="blogs()">
                            <img src="../assets/img/Media/MediaBan3.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">

                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th
                                    October, 2024</button>

                                <h5 class="card-title mt-3">Blogs</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="custom-line"></div>
            <div class="container mt-5">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Social media</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2"
                            to="/social-media-detail">View All</router-link>
                    </div>
                </div>

                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-d5e374c3-386b-47d7-a0c4-67b1346965e7" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-1d47e0aa-a050-4f29-bba6-b4fdeada865c" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-33561550-38cb-432d-b26d-0bcbcf268b86" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Newsroom</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2"
                            to="/Newsroom">View All</router-link>

                    </div>
                </div>
                <div class="row">
                    <!-- Card 1 -->

                    <div class="col-md-4" v-for="(item, index) in paginatedNewsroom" :key="index">
            <div class="card border-0">
              <img
                :src="item.Image_url"
                class="card-img-top"
                :alt="item.arete_title"
                style="border: 1px solid black; border-radius: 39px;"
              />
              <div class="card-body border-0">
                <button
                  type="button"
                  class="btn btn-outline-dark rounded-pill custom-badge-load px-4"
                >
                  News
                </button>
                <button
                  type="button"
                  class="btn btn-outline-dark rounded-pill custom-badge-load"
                >
                  {{ item.Ndate }}
                </button>
                <h3 class="mt-3">{{ item.Ntitle }}</h3>
                <p>
                  {{ truncatedText(item.arete_description) }}...
                  <a :href="item.arete_ReadMore" target="_blank" class="bgcolor">Read More</a>
                </p>
              </div>
            </div>
          </div>


                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Print media</h2>
                    </div>
                    <!-- <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2"
                            to="/social-media-detail">View All</router-link>

                    </div> -->
                </div>
                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/Aviation.png" class="card-img-top front"
                                    alt="Aviation Times">
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/The_Morning_Standard.png" class="card-img-top front"
                                    alt="The Morning Standard">
                             
                            </div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/Sunday_Standard.png" class="card-img-top front"
                                    alt="The Sunday Standard">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="cleartx bganimationcsr">
            <div class="container-fluid ">

                <form>
                    <div class="row" data-aos="fade-up">
                    
                    <div class="col-sm-6 d-flex justify-content-center p-2">
                        <img src="../assets/img/About/Vector.webp" alt="img-fluid" srcset="">
                    </div>
                    
                        <div class="col-sm-5 p-2">
                            <h2 class="mediaTitle">Media Kit</h2><br>
                            <form  @submit.prevent="checkSubmit" method="post">

                            <div class="mb-3 row">
                                <div class="col-sm-6 align-self-center  ">
                                    <label for="identitySelect" class="form-label">I would like to request for
                                        identities of:</label>
                                </div>
                                <div class="col-sm-5">
                                    <select class="form-control" id="identity" v-model="selectedIdentity">
                                        <option value="">Select Logo</option>
                                        <option value="AreteLogo">Arete Logo</option>
                                        <option value="Alive">Alive</option>
                                        <option value="Chere">Chere</option>
                                        <option value="Legend_Square">Legend Square</option>
                                        <option value="Legent_Sports">Legent Sports</option>
                                        <option value="PIP">PIP</option>
                                        <option value="Woodland">Woodland</option>
                                        <option value="Galaxy_High_street">Galaxy High street</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-5 m-0">
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="png" id="png" v-model="selectedFormats">
                                    <label class="form-check-label" for="png">
                                        Png
                                    </label>
                                </div>
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="jpg" id="Jpeg" v-model="selectedFormats">
                                    <label class="form-check-label" for="jpg">
                                        Jpeg
                                    </label>
                                </div>
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="eps" id="Jpeg" v-model="selectedFormats">
                                    <label class="form-check-label" for="eps">
                                        Eps
                                    </label>
                                </div>
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="pdf" id="pdf" v-model="selectedFormats">
                                    <label class="form-check-label" for="pdf">
                                        Pdf
                                    </label>
                                </div>
                                <div class="form-check col-sm-3">
                                    <input class="form-check-input" type="checkbox" value="zip" id="zip" v-model="selectedFormats" >
                                    <label class="form-check-label" for="allformate">
                                        all the formats
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control border-0 border-bottom inputbord"
                                        id="staticEmail" v-model="email" placeholder="brucewayne@domain.com" @blur="validateEmail" >
                                        <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                                </div>
                            </div>
                            <div class="mb-3 mt-5 m-0">
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2" @click="downloadFiles">Download</button>
                            </div>
                            </form>
                            
                        </div>
                    </div>
                    
                </form>
            </div>
        </section>

    </div>
</template>

<script>
import { mapGetters,mapActions } from "vuex"
import { useHead } from '@vueuse/head';

useHead({
  title: 'Media | Arete Group',
  meta: [
    {
      name: 'description',
      content: 'Explore PR, news coverages, blogs, and social media handles of Arete Grgoup',
    },
    {
      property: 'og:title',
      content: 'Media | Arete Group',
    },
    {
      property: 'og:description',
      content: 'Explore PR, news coverages, blogs, and social media handles of Arete Grgoup',
    },
    {
      name: 'keywords',
      content: 'arete group vapi media, arete group news coverage, arete group public relations, arete group social media, arete group blog posts',
    },
  ],
});


export default {
    data() {
    return {
      selectedIdentity:"",
      selectedFormats: [], // Store selected formats (checkbox values)
      email: '',
      emailError: null,
      files: {
        AreteLogo: {
          png: require("@/assets/img/Logo/logo.png"),
          jpg: require("@/assets/img/Logo/logo.jpg"),
          zip: require("@/assets/img/Logo/logo.zip"),
        },
        PIP: {
          png: require("@/assets/img/Logo/pip_logo.png"),
          jpg: require("@/assets/img/Logo/pip_logo.jpg"),
          pdf: require("@/assets/img/Logo/pip_logo.pdf"),
          eps: require("@/assets/img/Logo/pip_logo.eps"),
          zip: require("@/assets/img/Logo/pip_logo.zip"),
        },
        Chere: {
          png: require("@/assets/img/Logo/chere_logo.png"),
          jpg: require("@/assets/img/Logo/chere_logo.jpg"),
          pdf: require("@/assets/img/Logo/chere_logo.pdf"),
          eps: require("@/assets/img/Logo/chere_logo.eps"),
          zip: require("@/assets/img/Logo/chere_logo.zip"),
        },
        Alive: {
          png: require("@/assets/img/Logo/Alive_logo.png"),
          jpg: require("@/assets/img/Logo/Alive_logo.jpg"),
          zip: require("@/assets/img/Logo/Alive_logo.zip"),
          pdf: require("@/assets/img/Logo/Alive_logo.pdf"),
          eps: require("@/assets/img/Logo/Alive_logo.eps"),
        },
        Legend_Square: {
          png: require("@/assets/img/Logo/Legend_square.png"),
          jpg: require("@/assets/img/Logo/Legend_square.jpg"),
          pdf: require("@/assets/img/Logo/Legend_square.pdf"),
          eps: require("@/assets/img/Logo/Legend_square.eps"),
          zip: require("@/assets/img/Logo/Legend_square.zip"),
        },
        Legent_Sports: {
          png: require("@/assets/img/Logo/Legend_sport.png"),
          jpg: require("@/assets/img/Logo/Legend_sport.jpg"),
          pdf: require("@/assets/img/Logo/Legend_sport.pdf"),
          eps: require("@/assets/img/Logo/Legend_sport.eps"),
          zip: require("@/assets/img/Logo/Legend_sport.zip"),
        },
        Woodland: {
          png: require("@/assets/img/Logo/Woodlands.png"),
          jpg: require("@/assets/img/Logo/Woodlands.jpg"),
          pdf: require("@/assets/img/Logo/Woodlands.pdf"),
          eps: require("@/assets/img/Logo/Woodlands.eps"),
          zip: require("@/assets/img/Logo/Woodlands.zip"),
        },
        Galaxy_High_street: {
          png: require("@/assets/img/Logo/GalaxyHighStreet.png"),
          jpg: require("@/assets/img/Logo/GalaxyHighStreet.jpg"),
          pdf: require("@/assets/img/Logo/GalaxyHighStreet.pdf"),
          eps: require("@/assets/img/Logo/GalaxyHighStreet.eps"),
          zip: require("@/assets/img/Logo/GalaxyHighStreet.zip"),
        },
      },
      currentPage: 1, // Tracks the current page
      pageSize: 3, // Number of items per page

    };
  },
  computed: {
    ...mapActions(['submitdata']),
    ...mapGetters(["fetchNewsroom"]), // Fetch data from Vuex getter

    // Calculate total pages
    totalPages() {
      return Math.ceil(this.fetchNewsroom.length / this.pageSize);
    },
    // Get paginated items
    paginatedNewsroom() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.fetchNewsroom.slice(start, end);
    },

      },
    mounted() {
        // Dynamically add the Elfsight platform script
        const script = document.createElement("script");
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);
    },
    created() {
    this.loaders = true; // Show loader before fetching
    this.newsroom(); // Fetch newsroom data on component creation
  },
    methods: {
        truncatedText(tx) {
      if (!tx) return ""; // Handle cases where text is null or undefined
      return tx.split(" ").slice(0, 20).join(" ");
    },
        // Dispatch action to fetch newsroom data
    newsroom() {
      this.$store.dispatch("fetchNewsroom");
    },
        validateEmail() {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!this.email) {
                this.emailError = 'Email is required';
            } else if (!emailPattern.test(this.email)) {
                this.emailError = 'Please enter a valid email address';
            } else {
                this.emailError = null;
            }
        },
        selectAllFormats(event) {
      if (event.target.checked) {
        this.selectedFormats = [];
      } else {
        this.selectedFormats = [];
      }
    },
        // Trigger file download based on selected formats
    downloadFiles() {
      if (this.email === '') {
        alert("Please enter a valid email.");
        return;
      }
      
      let formatsToDownload = this.selectedFormats;
 
      if (formatsToDownload.length === 0) {
        alert("Please select at least one format to download.");
        return;
      }

      if(this.emailError)
      {
        alert("Please enter a valid email.");
        return;
      }
 
        this.checkSubmit()
        formatsToDownload.forEach(format => {
        const link = document.createElement('a');
        const selectedFiles = this.files[this.selectedIdentity];

        if(selectedFiles[format]=='zip')
        {
            link.href = selectedFiles[format]; // File name to download
            link.download = selectedFiles[format]; // File name on download
        }
        else
        {
            link.href = selectedFiles[format]; // File name to download
            link.download = selectedFiles[format]; // File name on download
        }

      


        link.download = `${this.selectedIdentity}.${format === 'jpeg' ? 'jpg' : format}`;
        link.click(); // Trigger the download
      });
 
      //alert(`Download initiated for ${formatsToDownload.join(", ")} formats.`);
    },
        blogs() {
            this.$router.push({ name: 'Mainblogs' });
        },
        Newsroom(){
            this.$router.push({ name: 'Newsroom' });
        },
        async checkSubmit()
        { 
            let payload={
                email:this.email,
            }

            console.log('payload',payload);

               // await this.$store.dispatch('submitMediaKit',payload);

                    this.$swal({
                            text: 'Thank you for contacting us! Your message has been successfully received.',
                            target: '#custom-target',
                            timer: 2000,
                            toast: true,
                            position: 'top'
                        });

                        this.clearForm();
                    // setTimeout(() => {
                    //   //this.$router.push('/contactus');
                    // }, 1000);
        
        },
        clearForm() {
            this.name="";
            this.email="";
        }

        }
};
</script>


<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}


.print-media-flip {
    perspective: 1000px;
    /* Give depth for the 3D effect */
    width: 100%;
    /* Full width */
    height: 300px;
    /* Fixed height for cards */
}

.print-media-card {
    position: relative;
    width: 100%;
    height: 100%;
    /* Full height */
    transition: transform 0.6s;
    transform-style: preserve-3d;
}



.eapps-widget-toolbar-panel {
    display: none;
}

@media (max-width: 600px) {
    .card {
        height: 50%;
    }
}

</style>