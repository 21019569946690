<template>
  <div>
    
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/csr/banner.webp" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">CSR</h2>
            </div>
        </div>
    </section>

    <!-- <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="col-sm-12  mb-1">
                    <h2 class="csrtitle" data-aos="fade-up">Prioritising Mental Health: U-Matter</h2><br>
                    <p class="csrdescription" data-aos="fade-up">U-Matter is a special initiative that has been instituted to call attention to the importance of mental health. Especially in today’s times of nuclear families and increasing isolation amongst people.</p>
                </div>
                <div class="col-sm-5  csrPrioritising d-flex align-items-center mb-4">
                    <p class="csrdescriptionBold p-5" data-aos="fade-up">“It’s okay to not be okay” is the message put forth by U-Matter as it endeavors to do away with the stigma around mental health, and around seeking help. It attempts to normalize the act of seeking counselling or therapy.</p>
                </div>
                <div class="col-sm-7  mb-4">

                    <swiper :slidesPerView="1" 
                                    :spaceBetween="40"
                                    :centeredSlides="true"
                                    :autoplay="{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }"
                                    :pagination="false"
                                    :navigation="false"
                                    :modules="modules"
                                    class="mySwiper"
                                    loop="true"
                                    :breakpoints="{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    }
                                    }" >
                                        <swiper-slide><img src="../assets/img/csr/slider/1.webp" class="d-block w-100" alt="Slider 1" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/csr/slider/2.webp" class="d-block w-100" alt="Slider 2" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/csr/slider/3.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/csr/slider/4.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/csr/slider/5.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        <swiper-slide><img src="../assets/img/csr/slider/6.webp" class="d-block w-100" alt="Slider 3" style="height: 454px;"></swiper-slide>
                                        
                                    </swiper>
                </div>
                <div class="col-sm-12 mt-4 ">
                    <p class="csrdescriptiondet" data-aos="fade-up">By providing therapy, counselling, psychologists and psychiatric interventions, whenever needed, U-Matter will not only enable Areteians (our employees) to focus on their mental well-being, but will also help the community at large.</p>
                </div>
            </div>
        </div>
    </section>
 
    <section class="csrbgcolor">
            <div class="container ">
            <div class="row p-4 gx-5">
                <div class="col-sm-8  col align-self-center mt-5 mb-5 " >
                    <h2 class="csrtitle-second" data-aos="fade-up">Making a Difference with Technology</h2><br>
                    <p class="csrdescription" data-aos="fade-up">The initiative is already supporting over 2,500 people for their mental well-being needs through measures such as one-to-one personal counselling, mental health events and wellness fairs. </p>
                    <p class="csrdescription" data-aos="fade-up">It leverages technology to deliver employee assistance in innovative forms, including individual therapy sessions, AI powered gamified self-help tools like journaling, games based on neuroscience, guided meditation and habit trackers.</p>
                </div>
                <div class="col-sm-4  d-flex justify-content-around mt-5 mb-5 p-1 p-md-5" data-aos="fade-up">
                    <img src="../assets/img/csr/csr.webp" alt="" srcset="" class="img-fluid fill-available">
                </div>
            </div>
            </div>
    </section> -->

    <section class="cleartx ">
        <div class="container csrbackgndimg">
            <div class="row  d-flex flex-column-reverse-mobile">
                <div class="col-sm-6 d-flex flex-column justify-content-between mb-5">
                    <img src="../assets/img/csr/community.webp" data-aos="fade-up"  alt="Group of people promoting mental health w-100" class="img-fluid fill-available">
                    </div>
                <div class="col-sm-6" data-aos="fade-up">
                    <h2 class="csrtitle-third ms-md-5 mb-3">Giving Back To The Community</h2>
                        <p class="csrdescription ms-md-5">At Arete, giving back to the community isn’t just an obligation—it’s an honor. Through the Jiya Foundation, we go beyond gestures of goodwill; we strive to ignite lasting change. Our mission is simple yet powerful: to uplift the lives of those who have supported us by creating pathways to growth. Whether it's through access to quality healthcare or empowering education, we are committed to turning our vision into action, making a meaningful impact that touches every corner of the community. Because at Arete, we don’t just give back—we invest in brighter futures.</p>
                    </div>
                    
                    <div class="col-sm-12">
                            <p class="csrdescription ">At Jiya Foundation, we are driven by a vision: to empower communities by providing access to inclusive healthcare and transformative education, paving the way for growth and a brighter future for all.</p>
                            <p class="csrdescription ">Through the Jiya Foundation, we’re not just improving lives—we’re building a healthier, more educated, and empowered society, one step at a time. This is our commitment, and together with our communities, we are turning possibilities into realities.</p>
                        </div>
                   
            </div>
        </div>
    </section>
    
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { useHead } from '@vueuse/head';

useHead({
  title: 'Corporate Social Responsibilities | Arete Group',
  meta: [
    {
      name: 'description',
      content: 'Discover how Arete is making a lasting impact on the community. Our commitment goes beyond giving back—its about creating pathways to growth through healthcare, education, and empowerment.',
    },
    {
      property: 'og:title',
      content: 'Corporate Social Responsibilities | Arete Group',
    },
    {
      property: 'og:description',
      content: 'Discover how Arete is making a lasting impact on the community. Our commitment goes beyond giving back—its about creating pathways to growth through healthcare, education, and empowerment.',
    },
    {
      name: 'keywords',
      content: 'Arete group CSR, Arete Vapi initiatives, arete group philanthropy, arete Corporate Social Responsibility Programs, arete Community Support',
    },
  ],
});


export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {};
  },
  methods: {
    // Add your methods here
  },
};



</script>

<style scoped>

.csrbackgndimg{
    background-image: url('/src/assets/img/csr/acolour1.png');
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: left;
}


.csrsecrbg{
        background-image: url('/src/assets/img/csr/csrline.png');
        /* background-size: cover;   Makes the image cover the entire element */
        background-repeat: no-repeat; /* Prevents the image from repeating */
        background-position: right;
    }
    .ms-2-5 {
  margin-left: 0.625rem; /* 2.5 divided by 4 (Bootstrap's base spacing unit) */
}
</style>