<template>
  <div>
    <h1>Facebook Post Embed</h1>
    <div
      class="fb-post"
      :data-href="postUrl"
      :data-width="width"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'FacebookPostEmbed', // Add a proper component name to avoid linting issues
  data() {
    return {
      appId: '3923301534583935', // Replace with your Facebook App ID
      postUrl: 'https://www.facebook.com/people/Arete-Group/61567374567558/', // Replace with the Facebook post URL
      width: '500', // Width of the embedded post
    };
  },
  mounted() {
    this.loadFacebookSDK();
  },
  methods: {
    loadFacebookSDK() {
      // Initialize Facebook SDK
      window.fbAsyncInit = () => {
        // eslint-disable-next-line no-undef
        FB.init({
          appId: this.appId,
          cookie: true,
          xfbml: true,
          version: 'v16.0', // Use the latest version of the Facebook API
        });

        // Parse the Facebook post after SDK initialization
        this.parseFacebookPost();
      };

      // Load the Facebook SDK script if it hasn't been loaded yet
      if (!window.FB) {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        document.body.appendChild(script);
      } else {
        // If the SDK is already loaded, parse the post immediately
        this.parseFacebookPost();
      }
    },
    parseFacebookPost() {
      // Parse the Facebook XFBML content
      if (window.FB && window.FB.XFBML) {
        window.FB.XFBML.parse();
      }
    },
  },
};
</script>

<style>
/* Add styles if needed */
</style>